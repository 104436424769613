@font-face {
  font-family: 'Helvetica Neue LT GEO';
  src: url('../../assets/fonts/helvetica/HelveticaNeueLTGEO-55Roman.eot');
  src: url('../../assets/fonts/helvetica/HelveticaNeueLTGEO-55Roman.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/helvetica/HelveticaNeueLTGEO-55Roman.woff2')
      format('woff2'),
    url('../../assets/fonts/helvetica/HelveticaNeueLTGEO-55Roman.woff')
      format('woff'),
    url('../../assets/fonts/helvetica/HelveticaNeueLTGEO-55Roman.ttf')
      format('truetype'),
    url('../../assets/fonts/helvetica/HelveticaNeueLTGEO-55Roman.svg#HelveticaNeueLTGEO-55Roman')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Helvetica Caps';
  src: url('../../assets/fonts/helvetica-Caps/HelveticaNeueLTGEOCaps-55Rmn.eot');
  src: url('../../assets/fonts/helvetica-Caps/HelveticaNeueLTGEOCaps-55Rmn.eot?#iefix')
      format('embedded-opentype'),
    url('../../assets/fonts/helvetica-Caps/HelveticaNeueLTGEOCaps-55Rmn.woff2')
      format('woff2'),
    url('../../assets/fonts/helvetica-Caps/HelveticaNeueLTGEOCaps-55Rmn.woff')
      format('woff'),
    url('../../assets/fonts/helvetica-Caps/HelveticaNeueLTGEOCaps-55Rmn.ttf')
      format('truetype'),
    url('../../assets/fonts/helvetica-Caps/HelveticaNeueLTGEOCaps-55Rmn.svg#HelveticaNeueLTGEOCaps-55Rmn')
      format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
